#header {
	background: none repeat center top $header-background-color;
	height: 48px;
	position: fixed;
	width: 100%;
	z-index: 100;
	#header-inner {
	  background: none no-repeat 0 0 transparent;
		margin: 0 auto;
		max-width: $max-width;
		border-radius: 0;
		padding: 0;
		position: relative;
		.brand-wrapper {
		  @include clearfix;
		  float: right;
		  position: relative;
		  width: calc(100% - 58px);
		  .dealer-logo {
				@include clearfix;
				color: $white;
				float: left;
				font-size: 1rem;
				margin: 0;
				position: relative;
				width: 180px;
				a {
					color: $white;
					&:hover {
						text-decoration: none;
					}
				}
				img, svg {
					display: inline-block;
					float: left;
					margin: 15px 10px 0 0;
					max-width: 60px;
				}
				.logo-text {
					float: left;
					line-height: 1;
					margin: 10px 0 0;
				}
				.red-text {
					color: $brand-red;
					display: block;
					font-weight: 600;
				}
		  }
		  .audi-rings-logo {
		    background: transparent url("/img/Rings_Solid-RGB-bl.svg") no-repeat scroll 0 0 / contain;
		    display: block;
		    height: 30px;
  		  float: right;
  		  margin: 11.5px 0 0;
  		  position: relative;
  		  right: 10px;
  		  width: 87px;
  		}
		}
		#header-region {
		  text-align: center;
			.utility-nav {
			  margin: .5em 0 0;
				ul {
					float: right;
					li {
					  	border-left: 1px solid $brand-red;
						display: inline-block;
						font-size: .875em;
						line-height: 1em;
						padding: 0 0.75em;
						&:first-child { border: none; }
						a {
							color: #000;
						}
						a:hover {
							color: $medium-gray;
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}

.whiteOverlay {
  background-color: rgba(255, 255, 255, 0);
  display: block;
  height: 100%;
  position: fixed;
  top: 0;
  transition: all 0.25s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 1;
}


.header-links {
	float: right;
	margin: 12px 10px 0 0;
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			display: inline-block;
			margin: 0 0 0 10px;
			&:first-child {
				margin: 0;
			}
			a {
				color: $white;
				span {
					border: 0px;
					clip: rect(0px, 0px, 0px, 0px);
					height: 1px;
					margin: -1px;
					overflow: hidden;
					padding: 0px;
					position: absolute;
					width: 1px;
				}
			}
		}
	}
}


.user-title
{
	display:flex;
	justify-content: space-between;
}

.user-title .button {
	background-color: #cc0033;
}


/* Tablet styles 
  --------------------------------------------------------------------- */
  @media all and (min-width: 480px) {

	.header-links {
		margin: 12px 16px 0 0;
		ul {
			li {
				margin: 0 0 0 20px;
			}
		}
	}

}


  @media all and (min-width: 1024px) {


	#header {
		height: auto;
		position: relative;
		#header-inner {
			padding: 0 1em;
			.brand-wrapper {
				float: left;
				width: 100%;
				.dealer-logo {
					width: 60%;
					.logo-text {
						font-size: 1.5rem;
						margin: 16px 0 0;
					}
					img, svg {
						margin: 15px 10px 15px 0;
						max-width: 70px;
					}
					.red-text {
						display: inline-block;
					}
				}
				.audi-rings-logo {
					margin: 25px 0 0;
				}
			}
		}
	}

	.header-links {
		margin: 16px 16px 0 0;
		ul {
			li {
				margin: 0 0 0 2rem;
				a {
					span {
						clip: initial;
						font-size: .813rem;
						height: auto;
						margin: 0 0 0 10px;
						overflow: initial;
						position: relative;
						vertical-align: super;
						width: auto;
					}
				}
			}
		}
	}


}



/* Desktop 1200 styles
  --------------------------------------------------------------------- */
@media all and (min-width: 1200px) {}