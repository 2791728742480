.submenu-side {
  list-style: none;
  margin: 0 0 1.5rem;
  padding: 0;
  li {
    border-top: 1px solid $brand-pale-grey-30;
    margin: 0;
    padding: 0;
    &:first-child {
      border: none;
    }
    &.sub-section {
      border: none;
      font-size: .875rem;
      a {
        padding: .125rem 0;
      }
    }
    a {
      display: block;
      padding: .5rem 0;
      text-decoration: none;
      &:hover {
        color: $black;
        text-decoration: underline;
      }
    }
  }
}

.searched-for {
  font-weight: 600;
  text-transform: capitalize;
}

.search-result-lead {
  border-bottom: 1px solid $light-gray;
  font-size: 1.25rem;
  margin: 2rem 0 1rem;
  padding: 0 0 1rem;
}

.search-result-list {
  margin: 1rem 0;
  .search-result-item {
    margin: 0 0 .375rem;
  }
}

.linkcheck-output {
  .link-links {
    a {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
  }
}

.welcome-block {
  margin: 3rem 0;
}

.dealer-image-container {
  img {
    height: auto;
    width: 100%;
  }
}


@media all and (min-width: 768px) {

  .welcome-block {
    margin: 3rem 0 3rem 3rem;
  }

}

@media all and (min-width: 960px) {

  .dealer-image-container {
    margin-left: 1.5rem;
  }

}