.media-embed, .embeddedContent, #video {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
	clear:both;
	float:none !important;
	margin:0 !important;
	iframe, object, embed, video {
  	position: absolute;
  	top: 0;
  	left: 0;
  	width:100% !important;
  	height: 100% !important;
  }
}