
@font-face{
    font-family:"Audi Type W01";
    src:url("/Fonts/ca001a68-8a3f-463a-b7c0-9d6c6d9fa7ad.eot?iefix") format("eot");}
    @font-face{
    font-family:"Audi Type W01";
    src:url("/Fonts/ca001a68-8a3f-463a-b7c0-9d6c6d9fa7ad.eot?iefix");
    src:url("/Fonts/e983524d-472d-40bc-8664-7e9035eb962e.woff") format("woff"),url("/Fonts/8f99ab5b-14ec-46e3-82e0-597f3a4b5ed9.ttf") format("truetype"),url("/Fonts/30599091-0458-4478-816e-eb88550a7b4f.svg#30599091-0458-4478-816e-eb88550a7b4f") format("svg");
    }
    @font-face{
    font-family:"Audi Type W01 Italic";
    src:url("/Fonts/fd37ae1c-11ea-4e05-98e0-94755eb0c88a.eot?iefix") format("eot");}
    @font-face{
    font-family:"Audi Type W01 Italic";
    src:url("/Fonts/fd37ae1c-11ea-4e05-98e0-94755eb0c88a.eot?iefix");
    src:url("/Fonts/a496fc45-5f92-49ca-8699-70aa8f4fdb5e.woff") format("woff"),url("/Fonts/3376d7c5-d236-44e4-a897-7c0a0e481151.ttf") format("truetype"),url("/Fonts/06265ea4-6407-4555-b419-386180458751.svg#06265ea4-6407-4555-b419-386180458751") format("svg");
    }
    @font-face{
    font-family:"Audi Type W01 Bold";
    src:url("/Fonts/bb842713-60c7-4886-b6d6-16bb9a99664d.eot?iefix") format("eot");}
    @font-face{
    font-family:"Audi Type W01 Bold";
    src:url("/Fonts/bb842713-60c7-4886-b6d6-16bb9a99664d.eot?iefix");
    src:url("/Fonts/149853c4-606f-48bc-ae60-b1fb143a1b3f.woff") format("woff"),url("/Fonts/40fe6559-6c9f-4c77-a1ef-4ede3bfca1ea.ttf") format("truetype"),url("/Fonts/44b3f704-8de9-4fa6-b120-803deaf58290.svg#44b3f704-8de9-4fa6-b120-803deaf58290") format("svg");
    }
    @font-face{
    font-family:"Audi Type W01 Bold Italic";
    src:url("/Fonts/ae52f1ca-0749-4514-8f96-505110776364.eot?iefix") format("eot");}
    @font-face{
    font-family:"Audi Type W01 Bold Italic";
    src:url("/Fonts/ae52f1ca-0749-4514-8f96-505110776364.eot?iefix");
    src:url("/Fonts/8fc18e86-91cb-4533-9683-dee67073bcf1.woff") format("woff"),url("/Fonts/2d416f3c-caad-460c-a2e6-35f221aac568.ttf") format("truetype"),url("/Fonts/b6a60ff1-60b6-48b2-bd47-fd5b11c73640.svg#b6a60ff1-60b6-48b2-bd47-fd5b11c73640") format("svg");
    }
    @font-face{
    font-family:"Audi Type W01 Ext";
    src:url("/Fonts/65045e4b-1704-4137-977b-da500ff233b4.eot?iefix") format("eot");}
    @font-face{
    font-family:"Audi Type W01 Ext";
    src:url("/Fonts/65045e4b-1704-4137-977b-da500ff233b4.eot?iefix");
    src:url("/Fonts/133c2924-abf5-490d-be63-4a450b510dab.woff") format("woff"),url("/Fonts/0cd2f673-44ae-4f47-895d-c603d9c57fc9.ttf") format("truetype"),url("/Fonts/64219619-37f8-449c-a6ad-1e4985ff0ea3.svg#64219619-37f8-449c-a6ad-1e4985ff0ea3") format("svg");
    }
    @font-face{
    font-family:"Audi Type W01 Ext Italic";
    src:url("/Fonts/cc91e576-dc48-4642-b7cb-366ec5b7539b.eot?iefix") format("eot");}
    @font-face{
    font-family:"Audi Type W01 Ext Italic";
    src:url("/Fonts/cc91e576-dc48-4642-b7cb-366ec5b7539b.eot?iefix");
    src:url("/Fonts/a037a3de-49dd-423c-8c08-6da9a21a92b9.woff") format("woff"),url("/Fonts/c92969fe-cf5d-4a1c-bab2-9cffe484966c.ttf") format("truetype"),url("/Fonts/211668ef-d20d-48da-86b7-79957312345c.svg#211668ef-d20d-48da-86b7-79957312345c") format("svg");
    }
    @font-face{
    font-family:"Audi Type W01 Ext Bold";
    src:url("/Fonts/57a1ec4e-d04d-4129-9888-930e3d0ce847.eot?iefix") format("eot");}
    @font-face{
    font-family:"Audi Type W01 Ext Bold";
    src:url("/Fonts/57a1ec4e-d04d-4129-9888-930e3d0ce847.eot?iefix");
    src:url("/Fonts/c502a579-bc68-48bd-bfa6-db5d83cab5e9.woff") format("woff"),url("/Fonts/48e04c62-e51b-4d52-b1dd-bc8637507580.ttf") format("truetype"),url("/Fonts/4623fc77-d262-423e-9389-618fe6c816ee.svg#4623fc77-d262-423e-9389-618fe6c816ee") format("svg");
    }
    @font-face{
    font-family:"Audi Type W01 Ext Bold Italic";
    src:url("/Fonts/4a30b84a-6d8d-4a45-a1d5-426959b82efb.eot?iefix") format("eot");}
    @font-face{
    font-family:"Audi Type W01 Ext Bold Italic";
    src:url("/Fonts/4a30b84a-6d8d-4a45-a1d5-426959b82efb.eot?iefix");
    src:url("/Fonts/50eb7949-e55f-4473-81f0-0bc5e1ebd268.woff") format("woff"),url("/Fonts/7fd570e4-1b2b-4e47-aa25-1226b4c221ed.ttf") format("truetype"),url("/Fonts/ec2bbced-0721-47d7-945c-b38daac14345.svg#ec2bbced-0721-47d7-945c-b38daac14345") format("svg");
    }
    @font-face{
    font-family:"Audi Type";
    src:url("/Fonts/9e07819d-c2a7-4f2b-9e8b-92ae7c9262af.eot?iefix") format("eot");}
    @font-face{
    font-family:"Audi Type";
    src:url("/Fonts/9e07819d-c2a7-4f2b-9e8b-92ae7c9262af.eot?iefix");
    src:url("/Fonts/de0b8bf6-ca0d-4839-818b-92bddb44eaeb.woff") format("woff"),url("/Fonts/2dff57d8-d77c-4537-a78d-e9e48578607a.ttf") format("truetype"),url("/Fonts/610030f6-5069-47ac-99dd-76f20cdb0fac.svg#610030f6-5069-47ac-99dd-76f20cdb0fac") format("svg");
    }
    @font-face{
    font-family:"Audi Type Italic";
    src:url("/Fonts/d00a8e0c-9a8c-4884-aa6a-47da1592b9e0.eot?iefix") format("eot");}
    @font-face{
    font-family:"Audi Type Italic";
    src:url("/Fonts/d00a8e0c-9a8c-4884-aa6a-47da1592b9e0.eot?iefix");
    src:url("/Fonts/83f5f0c2-54fb-452c-98e8-3553aabc6d40.woff") format("woff"),url("/Fonts/9b8409df-5f5a-4b2d-a3e7-7d430dc7e030.ttf") format("truetype"),url("/Fonts/725d8b40-1378-4aca-951a-1da4bd465663.svg#725d8b40-1378-4aca-951a-1da4bd465663") format("svg");
    }
    @font-face{
    font-family:"Audi Type Bold";
    src:url("/Fonts/c719eda5-e635-40fd-8b1c-869ba391dfff.eot?iefix") format("eot");}
    @font-face{
    font-family:"Audi Type Bold";
    src:url("/Fonts/c719eda5-e635-40fd-8b1c-869ba391dfff.eot?iefix");
    src:url("/Fonts/34d005d1-9444-4a58-af68-4f73c4939ce0.woff") format("woff"),url("/Fonts/e6b424cc-7cd0-44d3-8bcb-ab7ee5c5ae1a.ttf") format("truetype"),url("/Fonts/f7789a38-da66-4f5f-b33f-9084cd6727c8.svg#f7789a38-da66-4f5f-b33f-9084cd6727c8") format("svg");
    }
    @font-face{
    font-family:"Audi Type Bold Italic";
    src:url("/Fonts/6c5142af-452e-4d34-879f-eb6e46fe6df3.eot?iefix") format("eot");}
    @font-face{
    font-family:"Audi Type Bold Italic";
    src:url("/Fonts/6c5142af-452e-4d34-879f-eb6e46fe6df3.eot?iefix");
    src:url("/Fonts/70ed7ac5-55e9-41d8-b71c-2959ddd6b38b.woff") format("woff"),url("/Fonts/0e6d153f-e3dd-4612-802b-c426b463feca.ttf") format("truetype"),url("/Fonts/fe20912f-4292-44ab-91fd-31b84e68ed48.svg#fe20912f-4292-44ab-91fd-31b84e68ed48") format("svg");
    }
    @font-face{
    font-family:"Audi Type Ext";
    src:url("/Fonts/e8544113-fe09-41aa-8599-27b708522875.eot?iefix") format("eot");}
    @font-face{
    font-family:"Audi Type Ext";
    src:url("/Fonts/e8544113-fe09-41aa-8599-27b708522875.eot?iefix");
    src:url("/Fonts/3cf06a69-cc8c-4e63-aacb-e8138295b55f.woff") format("woff"),url("/Fonts/544c902b-c37e-45a7-8848-3eb58c33cbc3.ttf") format("truetype"),url("/Fonts/df225f05-983e-45f5-8881-258cfe73b15f.svg#df225f05-983e-45f5-8881-258cfe73b15f") format("svg");
    }
    @font-face{
    font-family:"Audi Type Ext Italic";
    src:url("/Fonts/c39f5606-26f4-4659-8d8b-2d95bafaef8b.eot?iefix") format("eot");}
    @font-face{
    font-family:"Audi Type Ext Italic";
    src:url("/Fonts/c39f5606-26f4-4659-8d8b-2d95bafaef8b.eot?iefix");
    src:url("/Fonts/809f78c6-dada-4caf-a7a0-08c8b5ff4951.woff") format("woff"),url("/Fonts/d9544a79-93b7-405e-9db8-bd6c784d8ada.ttf") format("truetype"),url("/Fonts/8a07a511-1402-46cf-aa13-971aa867193d.svg#8a07a511-1402-46cf-aa13-971aa867193d") format("svg");
    }
    @font-face{
    font-family:"Audi Type Ext Bold";
    src:url("/Fonts/0d9df420-76ab-40a9-ae57-2461c1c6ab6d.eot?iefix") format("eot");}
    @font-face{
    font-family:"Audi Type Ext Bold";
    src:url("/Fonts/0d9df420-76ab-40a9-ae57-2461c1c6ab6d.eot?iefix");
    src:url("/Fonts/a3c162ba-a8e8-4fc5-93d6-86d391105a57.woff") format("woff"),url("/Fonts/68930f0b-94a1-4c0e-a5ae-f8e3ad3b49a7.ttf") format("truetype"),url("/Fonts/e26927bb-0f7e-450e-87af-b5220869dd73.svg#e26927bb-0f7e-450e-87af-b5220869dd73") format("svg");
    }
    @font-face{
    font-family:"Audi Type Ext Bold Italic";
    src:url("/Fonts/bcbc99a8-48e9-4fb0-b930-f6c3965c66e8.eot?iefix") format("eot");}
    @font-face{
    font-family:"Audi Type Ext Bold Italic";
    src:url("/Fonts/bcbc99a8-48e9-4fb0-b930-f6c3965c66e8.eot?iefix");
    src:url("/Fonts/e29bb50a-53f2-4ef9-99f6-0f64213aa5ad.woff") format("woff"),url("/Fonts/1140f8d6-ca19-41e9-a749-0696504dbaa6.ttf") format("truetype"),url("/Fonts/5d97548b-4390-488e-af2d-0f34ad2a8862.svg#5d97548b-4390-488e-af2d-0f34ad2a8862") format("svg");
    }
