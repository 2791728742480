#footer {
	#footer-inner {
		color: $footer-color-text;
		margin: auto;
		max-width: $max-width;
		padding: 0 1em;
		.footer-block {
		  @include clearfix;
		  padding: 3rem 0 1rem;
      text-align: center;
		  p {
			  margin: 0;
			  line-height: 1.5em;
      }
      .copyright-details {
        font-size: .75rem;
      }
		}
	}
}

#footer ul {
  padding-left: 0;
}
#footer-links {
  margin: 1em 0;
  li {
    display: inline-block;
    margin: 0 1em 0 0;
    a {
      background-color: transparent;
      background-image: url("/img/global-icons.png");
      background-repeat: no-repeat;
      display: block;
      height: 26px;
      text-indent: -9999px;
      width: 26px;
      &.dashboard {
        background-position: -56px -425px;
        &:hover {
          background-position: -88px -425px;
        }
      }
      &.contact {
        background-position: -99px -521px;
        &:hover {
          background-position: -131px -521px;
        }
      }
      &.logout {
        background-position: -36px -1130px;
        &:hover {
          background-position: -132px -1450px;
        }
      }
    }
  }
}

.social-media-links {
  margin: 0 0 1.5rem;
  ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        margin: 0 1%;
        a {
          color: $white;
          display: inline-block;
          span {
            border: 0px;
            clip: rect(0px, 0px, 0px, 0px);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0px;
            position: absolute;
            width: 1px;
          }
          &:hover {
            color: $brand-pale-grey;
          }
        }
      }
  }
}

/* Tablet styles 
  --------------------------------------------------------------------- */
@media all and (min-width: 480px) and (max-width: 959px) {}


/* Large Tablet 768+ styles 
  --------------------------------------------------------------------- */
@media all and (min-width: 768px) {}


/* Desktop 960+ styles 
  --------------------------------------------------------------------- */
@media all and (min-width: 960px) {}


/* Desktop 1200 styles 
  --------------------------------------------------------------------- */
@media all and (min-width: 1200px) {

  #footer {
  	#footer-inner {
      padding: 0 4em;
  		.footer-block {
        padding: 2.5rem 0 1.5rem;
        .copyright-details {
          float: left;
          padding: 10px 0 0;
          text-align: left;
          width: 50%;
        }
      }
  	}
  }

  .social-media-links {
    float: right;
    margin: 0;
    text-align: right;
    width: 50%;
    ul {
      li {
        margin: 0 0 0 1rem;
      }
    }
  }

}