.button, a.button, a.btn {
  background-color: #000;
  border: 0 none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  min-width: 60px;
  outline: 0 none;
  padding: 0.7em 1em 0.6em;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s ease-in 0s;
	&:hover {
		background-color: #434c53;
	}
}




/* Tablet styles 
  --------------------------------------------------------------------- */
@media all and (min-width: 480px) and (max-width: 959px) {}


/* Large Tablet 768+ styles 
  --------------------------------------------------------------------- */
@media all and (min-width: 768px) {
  
  .button, a.button, a.btn {
    width: auto;
  }
  
}


/* Desktop 960+ styles 
  --------------------------------------------------------------------- */
@media all and (min-width: 960px) {}


/* Desktop 1200 styles 
  --------------------------------------------------------------------- */
@media all and (min-width: 1200px) {}