$form-border-color: $base-border-color;
$form-border-color-hover: darken($base-border-color, 10);
$form-border-color-focus: $base-accent-color;
$form-border-radius: $base-border-radius;
$form-box-shadow: inset 0 1px 3px hsla(0, 0%, 0%, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px rgba(darken($form-border-color-focus, 5), 0.7);
$form-font-size: $base-font-size;
$form-font-family: $base-font-family;

fieldset {
  background: lighten($base-border-color, 10);
  border: 1px solid $base-border-color;
  margin: 0 0 ($base-line-height / 2) 0;
  padding: $base-line-height;
}

input,
label,
select {
  display: block;
  font-family: $form-font-family;
  font-size: $form-font-size;
}

label {
  font-weight: bold;
  margin-bottom: $base-line-height / 4;

  &.required:after {
    content: "*";
  }

  abbr {
    display: none;
  }
}


input[type='text'] {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: -moz-use-text-color -moz-use-text-color #000;
  border-image: none;
  border-style: none none dotted;
  border-width: medium medium 1px;
  font-family: "Audi Type W01";
  font-size: 12px;
  margin: 0 0 20px;
  padding-bottom: 6px;
  width: 100%;
}


textarea {
  /*background-color: white;
  border-radius: $form-border-radius;
  border: 1px solid $form-border-color;
  box-shadow: $form-box-shadow;
  font-family: $form-font-family;
  font-size: $form-font-size;
  margin-bottom: $base-line-height / 2;
  padding: ($base-line-height / 3) ($base-line-height / 3);
  resize: vertical;
  width: 100%;

  &:hover {
    border-color: $form-border-color-hover;
  }

  &:focus {
    border-color: $form-border-color-focus;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }*/
}

input[type="search"] {
}

input[type="checkbox"], input[type="radio"] {
  display: inline;
  margin-right: $base-line-height / 4;
}

input[type="file"] {
  width: 100%;
}

select {
  width: auto;
  margin-bottom: $base-line-height;
}

select{
	-webkit-transition: border-color;
	-moz-transition: border-color;
	-ms-transition: border-color;
	-o-transition: border-color;
	transition: border-color;
	background-color: white;
	border: 1px solid #dddddd;
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 1em;
	margin-bottom: 0.75em;
	padding: 0.5em 0.5em;
	resize: vertical;
	width: 100%;

	&:hover{
		border-color: #c4c4c4;
	}
	&:focus {
		border-color: #fee429;
		box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(254, 225, 16, 0.7);
		outline: none;
	}
}

input,
label,
select {
  display: block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em; }

label {
  font-weight: bold;
  margin-bottom: 0.375em; }
  label.required:after {
    content: "*"; }
  label abbr {
    display: none; }

input[type="email"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="url"], input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="time"], input[type="week"] {
  -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: -moz-use-text-color -moz-use-text-color #000;
    border-image: none;
    border:none;
    border-bottom: solid 1px rgb(118, 118, 118);
    font-family: "Audi Type W01";
    font-size: 1rem;
    margin: 0 0 2rem;
    padding: 8px 2px;
    width: 100%;
   }
   
textarea {
     -moz-border-bottom-colors: none;
       -moz-border-left-colors: none;
       -moz-border-right-colors: none;
       -moz-border-top-colors: none;
       border-color: -moz-use-text-color -moz-use-text-color #000;
       border-image: none;
       border: solid 1px rgb(118, 118, 118);
       font-family: "Audi Type W01";
       font-size: 1rem;
       margin: 0 0 2rem;
       padding: 8px 2px;
       width: 100%;
      }



input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none; }

input[type="checkbox"], input[type="radio"] {
  display: inline;
  margin-right: 0.375em; }

input[type="file"] {
  width: 100%; }

select {
  width: auto;
  margin-bottom: 1.5em; }

select {
  -webkit-transition: border-color;
  -moz-transition: border-color;
  -ms-transition: border-color;
  -o-transition: border-color;
  transition: border-color;
  background-color: white;
  border: 1px solid #dddddd;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em;
  margin-bottom: 0.75em;
  padding: 0.5em 0.5em;
  resize: vertical;
  width: 100%; }
  select:hover {
    border-color: #c4c4c4; }
  select:focus {
    border-color: #fee429;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(254, 225, 16, 0.7);
    outline: none; }

 fieldset {
  background: #e6e6e6;
  border: 1px solid #cccccc;
  margin: 0 0 0.75em 0;
  padding: 1.5em; }

button, input[type="submit"] {
	cursor: pointer;
  float: right;
  margin-right:10px;
	vertical-align: middle;
	white-space: nowrap;
	padding: .875rem 1rem;
	border: $button-border;
	border-radius: $button-border-radius;
	font-weight: 600;
	background: $button-color-back;
	color: $button-color-text;
	&:hover {
		background: $button-color-back-hover;
	}
}

.registration-form button, .registration-form input[type="submit"] {
  float: none;
}