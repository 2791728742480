#main {
  @include clearfix;
	background: $main-color-back;
	float: none;
	padding: 48px 0 0;
	#main-inner {
		max-width: $max-width;
		margin: 0 auto;
		padding: 1em;
		background: $white;
		#content-header {
	    #banner {
        img {
          width: 100%;
        }
	    }
	    #breadcrumb {
        color: #999;
        font-size: 0.85em;
        margin: 1em 1em 2em;
        text-transform: capitalize;
	    }
		}
		#content-area {
	    p.reference {
        margin-top: 3em;
        font-size: 0.8em;
        color: #ccc;
	    }
	    .newsBlock{
	    	margin: 1em;
	    	.newsTitle{
	    		font-size: 2em;
	    		font-weight: normal;
	    		span {
					  font-size: 0.8em;
					  font-style: normal;
					  margin-left: 0.5em;
					}
        }
	    }
	    #dealership {
	    	margin: 3em 0;
	    	.dealershipImage{
	    		width: 33%;
	    		float:left;
	    		border-top: 3px solid black;
	    	    padding:25px 1em 0 0;
	    	}
	    	.dealershipInfo{
	    		width: 63%;
	    		float:left;
	    		margin-left: 2em;
	    		border-top: 1px solid #d5d9d8;
          padding:25px 1em 0 0;
    	    h2{
    	    	font-size:3em;
    	    	font-weight: normal;
    	    }
    	    p {
    	    	line-height: 1.8em;
    	    }
	    	}
	    }
		}
		#content.sidebar {
			margin: 0;
			padding: 0;
		}
		#sidebar {
			margin: 0 0 1.5em;
			h2 {
				margin: 0.5em 0 0.25em;
			}
			ul  {
		    padding: 0;
		    li {
			    list-style: none;
			    a.button {
    				padding: 0.25em 1em;
    				width: 100%;
    				text-align: center;
    				margin-bottom: 0.75em;
    				line-height: 1.25em;
    				&.active {
    					background: $button-color-back-active;
    				}
			    }
  			}
			}
      #sidebar-inner {
        h3 {
          font-size: 1.25rem;
          margin: 0 0 .375rem;
        }
      }
		}
	}
}


#news-highlight-block {
  @include clearfix;
  margin: 0 0 1em;
  position: relative;
  .image {
    border-top: 1px solid #d5d9d8;
    display: block;
    margin-right: 0;
    padding: 10px 0;
    img {
      height: auto;
      width: 100%;
    }
  }
  .text {
    border-top: 3px solid black;
    display: block;
    margin-right: 1.04944%;
    padding: 25px 0 40px;
    h2 {
      margin: 0 0 .5em;
    }
    h3 {
      color: $brand-dark-grey-60;
      font-size: 1.25em;
      margin: 0 0 1em;
    }
  }
}


#latest-news {
  @include clearfix;
  margin: 0 0 1.5em;
  .header {
    border-bottom: 1px solid #e5e8e7;
    overflow: auto;
    padding: 15px 0 0;
  }
  .news-list {
    @include clearfix;
    margin: 30px 0 0;
    position: relative;
    .block {
      box-sizing: border-box;
      height: auto;
      margin: 0 0 2.5em;
      padding: 0 .5%;
      .title, .date {
        margin: 0 0 1em;
      }
    }
  }
}


.subfooter {
  @include clearfix;
  #quicklinks {
    border-top: 3px solid #000;
    box-sizing: border-box;
    padding: 3em .5% 1em;
  }
  #contact-block {
    border-top: 1px solid #e5e8e7;
    box-sizing: border-box;
    padding: 3em .5% 1em;
    .socialmedia {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        float: left;
        margin: 0 0 0 10px;
        .icon {
          background-color: transparent;
          background-image: url("/img/global-icons.png");
          background-repeat: no-repeat;
          display: inline-block;
          height: 30px;
          text-indent: -9999px;
          vertical-align: middle;
          width: 30px;
          &.facebook {
            background-position: -120px -328px;
            &:hover {
              background-position: -150px -328px;
            }
          }
          &.twitter {
            background-position: -30px -358px;
            &:hover {
              background-position: -30px -238px;
            }
          }
          &.google {
            background-position: -20px -390px;
            &:hover {
              background-position: -50px -390px;
            }
          }
          &.youtube {
            background-position: 0 -358px;
            &:hover {
              background-position: -120px -71px;
            }
          }
          &.instagram {
            background-position: 0 -2888px;
            &:hover {
              background-position: -30px -2888px;
            }
          }
          &.linkedin {
            background-position: 0 -2854px;
            &:hover {
              background-position: -30px -2854px;
            }
          }
        }
      }
    }
  }
}

#searchBar {
  @include clearfix;
  cursor: text;
  margin: 0 0 1rem;
  position: relative;
  transition: border-bottom-color 0.1s ease-in 0s;
  z-index: 2;
  .button {
    background-color: transparent;
    background-image: url("/img/global-icons.png");
    background-repeat: no-repeat;
    background-position: -30px -1475px;
    display: inline-block;
    height: 37px;
    margin: 0;
    min-width: inherit;
    padding: 0;
    position: absolute;
    text-indent: -9999px;
    vertical-align: middle;
    width: 35px;
    /*&:hover {
      background-position: -64px -1638px;
    }*/
  }
  #user-search-form {
    input[type="text"] {
      border: 1px solid;
      font-family: "Audi Type W01 Ext Bold";
      font-size: 1rem;
      height: 38px;
      line-height: 28px;
      margin: 0;
      padding: 0 0 0 40px;
      color: $brand-dark-grey;
      max-width: 330px;
    }
  }
}


.executive-team-list {
  .member-card {
    margin: 0 0 3em;
    .image-container {
      margin: 0 0 .5em;
      img {
        width: 100%;
      }
    }
    .details-container {
      .title {
        h4 {
          margin: 0 0 .25em;
        }
      }
      .subtitle {
        color: $brand-dark-grey-60;
        font-family: "Audi Type W01 Ext Bold";
      }
    }
  }
}



table.checklist {
  font-size: 1.125em;
  thead {
    th {
      border: none;
    }
  }
  tbody {
    tr {
      td {
        padding: .875em 1em;
      }
    }
  }
}
ul#days {
  list-style: none;
  list-style-type: none;
  margin: 0 0 1.5em;
  padding: 0;
  width: 100%;
  li {
    margin: 0 0 1px;
    text-align: center;
    a {
      background: none no-repeat scroll 0 0 #000;
      color: #fff;
      display: block;
      font-family: "Audi Type W01 Ext Bold";
      padding: .75em 2em;
    }
    &.active {
      a {
        background: none no-repeat scroll 0 0 $brand-red;
      }
    }
  }
}



/* Tablet styles 
  --------------------------------------------------------------------- */
@media all and (min-width: 480px) and (max-width: 767px) {
  
  ul#days {
    li {
      display: inline-block;
      margin: 0;
      text-align: left;
      vertical-align: top;
      width: auto;
      a {
        padding: .75em .638em;
      }
    }
  }
  
}


/* Large Tablet 768+ styles 
  --------------------------------------------------------------------- */
@media all and (min-width: 768px) {
  
  #main {
  	#main-inner {
  	  #content-area {
  	    padding: 0 1.5rem 0 .625rem;
  	    .home-block1 {
    	    float: left;
    	    margin-right: 5%;
    	    padding: 25px 1em 0 0;
    	    width: 65%;
  	    }
  	    .home-block2 {
    	    float: left;
    	    padding: 25px 1em 0 0;
    	    width: 30%;
  	    }
  	  }
  	  #content.sidebar {
  		  margin-left: 25%;
  		}
  	  #sidebar {
  			float: left;
  			margin: 0;
  			padding: 25px 1em 0 0;
  			width: 25%;
			}
  	}
	}
	
	#news-highlight-block {
    .image {
      float: right;
      padding: 25px 0 40px;
      width: 66.3168%;
    }
    .text {
      float: left;
      width: 32.6337%;
      h2, h3, h4, h5 {
        padding-right: 10%;
      }
      p {
        padding: 0 10% 20px 0 !important;
      }
    }
  }
	
	#latest-news {
	  margin: 0 0 3em;
    .news-list {
      margin: 30px 10px;
      .block {
        float: left;
        margin: 0;
        width: 33%;
      }
    }
  }
	
	.subfooter {
	  #quicklinks {
      float: left;
      width: 33%;
    }
    #contact-block {
      float: right;
      width: 65.625%;
    }
	}
	
	
	.executive-team-list {
    .member-card {
      display: inline-block;
      margin: 0 2% 2em;
      vertical-align: top;
      width: 29%;
      &.top-border {
        border-top: 1px solid $brand-dark-grey-30;
        padding: 2em 0 0;
      }
    }
  }
  
  
  ul#days {
    li {
      display: inline-block;
      margin: 0;
      vertical-align: top;
      width: auto;
      a {
        padding: .75em 1.5em;
      }
    }
  }
	

  .user-landing-wrapper {
    display: flex;
    flex-flow: row wrap;
    .sidebar-dashboard {
      order: 1;
      flex: 0 0 25%;
      padding: 0 1rem 0 0;
      width: 25%;
    }
    #news-highlight-block {
      order: 2;
      flex: 0 0 75%;
      width: 75%;
    }
  }
  
}



/* Desktop 960+ styles 
  --------------------------------------------------------------------- */
@media all and (min-width: 960px) {
  
  #main {
    #main-inner {
  	  padding: 1em;
  	}
	}
	
	.subfooter {
  	#contact-block {
  	  position: relative;
  	  .socialmedia {
  	    position: absolute;
  	    right: 0;
  	    top: 3em;
  	  }
    }
	}
	
	ul#days {
    li {
      a {
        padding: .75em 2em;
      }
    }
  }
  
}


@media all and (min-width: 1024px) {

  #main {
    padding: 0;
  }

}


/* Desktop 1200 styles 
  --------------------------------------------------------------------- */
@media all and (min-width: 1200px) {}
