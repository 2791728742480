#superfooter {
	margin: 0;
	#superfooter-inner {
		background: $color-back-superfooter;
		max-width: $max-width;
		margin: auto;
		padding: 0.25em 0;
		text-align: center;
  		ul {
    		list-style: none;
    		list-style-type: none;
    		margin: 0;
    		padding: 0;
			li {
				display: inline-block;
				margin: 0;
				a {
					padding: 0 1em;
					border-left: 1px solid $color-text-superfooter;
					color: $color-text-superfooter;
					&:hover {
						color: $color-text-superfooter-hover;
					}
				}
				&:first-child a { 
					border-left: none;
				}
      		}
    	}
	}
}
