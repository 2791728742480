// Layout - General
$max-width: 1440px;
$max-full-width: 100%;

// Sizes
$base-font-size: 1em;
$base-line-height: $base-font-size * 1.5;
$base-border-radius: em(3);


// Client Branding Colors
$brand-red: #f50537;
$brand-dark-red: #aa142d;
$brand-white: #fff;
$brand-black: #000;
$brand-grey: #6d7579;
$brand-dark-grey: #434C53;
$brand-medium-grey: #6d7579;
$brand-light-grey: #B0B6B8;
$brand-pale-grey: #D5D9D8;
$brand-dark-grey-60: #8E9498;
$brand-medium-grey-60: #A7ACAF;
$brand-light-grey-60: #D0D3D4;
$brand-pale-grey-60: #e6e8e8;
$brand-dark-grey-30: #C6C9CB;
$brand-medium-grey-30: #D3D5D7;
$brand-light-grey-30: #E7E9EA;
$brand-pale-grey-30: #F2F4F3;


// General Colors
$black: #000;
$very-dark-gray: #333;
$dark-gray: #666;
$medium-gray: #999;
$light-gray: #EFF0F1;
$very-light-gray: #f0f0f0;
$light-red: #FBE3E4;
$dark-red: #91090e;
$light-green: #E6EFC2;
$light-yellow: #FFF6BF;
$very-light-yellow: #ffffee;
$yellow: #fee429;
$brown: #eecc29;
$blue: #13A2F2;
$light-orange: #fce;
$white: #FFFFFF;
$beige: #F0EEE2;
$orange: #EB8200;

//  Fonts
$base-font-color: $brand-black;
$base-accent-color: $brand-red;
$base-link-color: $brand-black;
$hover-link-color: $brand-red;
$h16-color-text: black;
$h16-color-text-hover: $dark-gray;

// Typography
$sans-serif: helvetica, sans-serif;
$serif: georgia, serif;
$base-font-family: $sans-serif;
$header-font-family: $base-font-family;

// Buttons
$button-color-back: $black;
$button-color-back-hover: $brand-red;
$button-color-back-active: $brand-dark-red;
$button-color-text: $white;
$button-border: none;
$button-border-radius: 0px;

// Borders
$base-border-color: $light-gray;

// Tables
$table-color-border: $light-gray;

// Flash Colors
$error-color: $light-red;
$notice-color: $light-yellow;
$success-color: $light-green;

// Regions

// Body
$body-color-back: $black;
$body-color-text: $base-font-color;

// Header Colors
$header-background-color: $black;
$header-color-back: $brand-red;
$header-color-text: $dark-gray;
$header-color-text-hover: $dark-gray;

// Nav
$nav-color-back: $brand-red;
$nav-color-back-hover: $dark-gray;
$nav-color-text: $white;
$nav-color-text-hover: $white;
$mobile-nav-slideout-bkgd: $black;
$mobile-nav-slideout-font-size: 1.25em;
$mobile-nav-slideout-font-color: $white;
$main-nav-region-bkgd: $brand-pale-grey-30;
$main-nav-link-color: $dark-gray;
$main-nav-link-color-hover: $black;

// Main
$main-color-back: $white;

// Superfooter Colors
$color-back-superfooter: $brand-grey;
$color-text-superfooter: $white;
$color-text-superfooter-hover: $light-gray;

// Footer Colors
$footer-color-back: $black;
$footer-color-text: $white;
