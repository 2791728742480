body {
	background: $body-color-back;
	margin: 0;
	padding: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

* {
  margin: 0;
  padding: 0; }

/* Clear Floats Without Structural Markup
  ---------------------------------------
  http://www.positioniseverything.net/easyclearing.html
*/
.clearfix {
  display: inline-block; }
  .clearfix:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden; }

/* Hides from IE-mac */
* html .clearfix {
  height: 1%; }

.clearfix {
  display: block; }
/* End hide from IE-mac */

/* clear classes */
.clearleft, .clearl, .cleft {
  clear: left; }

.clearright, .clearr, .cright {
  clear: right; }

.clear, .clearboth, .clearall {
  clear: both; }

/* Float classes */
.floatleft, .fleft, .floatl {
  float: left;
  margin: 0 10px 5px 0; }

.floatright, .fright, .floatr {
  float: right;
  margin: 0 0 5px 10px; }

/* "Skip to Main Content" link
  ---------------------------
  This link useful for both visually impaired people and those with mobility impairments
  who cannot use a mouse. http://www.webaim.org/techniques/css/invisiblecontent/
*/
#skip a:link, #skip a:hover, #skip a:visited {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden; }
#skip a:active, #skip a:focus {
  position: static;
  width: auto;
  height: auto; }

.wrap {
  *zoom: 1;
  margin-left: auto;
  margin-right: auto;
  margin: 0px auto; }
  .wrap:before, .wrap:after {
    content: " ";
    display: table; }
  .wrap:after {
    clear: both; }



.form-container {
  width: 55%;
}

#navigation #navigation-inner ul.menu > li.active > a {
	border-bottom: 1px solid $brand_red;
	font-weight: bold;
}



.login-screen {
  .landing-wrapper {
    background: $white;
    .messages {
      margin: 0 0 2rem;
      padding: .25rem .875rem;
    }
    .brand-wrapper {
      float: none;
      padding: 2rem 0;
      text-align: center;
      width: 100%;
      .dealer-logo {
        color: $black;
        float: none;
        font-size: 1.25rem;
        width: 100%;
        a {color: $black;}
        svg {
          float: none;
          display: inline-block;
          margin: 0 10px 0 0;
          max-width: 80px;
          vertical-align: middle;
        }
        .logo-text {
          display: inline-block;
          float: none;
          line-height: 1;
          margin: 0;
          text-align: left;
          vertical-align: middle;
          width: auto;
        }
        .red-text {
          color: $brand-red;
          display: inline-block;
          font-weight: 600;
        }
      }
    }
    #content {
      margin: 0 auto;
      max-width: 420px;
      padding: 2rem;
    }
  }
  #main {
    padding: 0;
    #main-inner {
      max-width: 100%;
      padding: 0;
    }
  }
  input[type=submit] {
    float: none;
    margin-top: 3rem;
    padding: 1.5rem 1rem;
    width: 100%;
  }
  .login-form-actions {
    font-size: .813rem;
    margin: 2.5rem 0 .5rem;
    a {
      color: $dark-gray;
      margin-right: 1rem;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}


.register-user-screen {
  .landing-wrapper {
    background: $white;
    .messages {
      margin: 0 0 2rem;
      padding: .25rem .875rem;
    }
    .brand-wrapper {
      float: none;
      padding: 2rem 0;
      text-align: center;
      width: 100%;
      .dealer-logo {
        color: $black;
        float: none;
        font-size: 1.25rem;
        width: 100%;
        a {color: $black;}
        svg {
          float: none;
          display: inline-block;
          margin: 0 10px 0 0;
          max-width: 80px;
          vertical-align: middle;
        }
        .logo-text {
          display: inline-block;
          float: none;
          line-height: 1;
          margin: 0;
          text-align: left;
          vertical-align: middle;
          width: auto;
        }
        .red-text {
          color: $brand-red;
          display: inline-block;
          font-weight: 600;
        }
      }
    }
    #content {
      margin: 0 auto;
      max-width: 640px;
      padding: 0 1rem 3rem;
    }
  }
  input[type=submit] {
    float: none;
    margin: 3rem auto;
    padding: 1.5rem 1rem;
    min-width: 200px;
    width: auto;
  }
  .login-form-actions {
    font-size: .813rem;
    margin: 2.5rem 0 .5rem;
    a {
      color: $dark-gray;
      margin-right: 1rem;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@media all and (min-width: 768px) {

  .login-screen, .register-user-screen {
    .landing-wrapper {
      .brand-wrapper {
       .dealer-logo {
         font-size: 1.375rem;
       }
      }
    }
  }

}

@media all and (min-width: 1024px) {

  .login-screen {
    .landing-wrapper {
      display: flex;
      flex-flow: row wrap;
      height: 80vh;
      overflow: hidden;
      position: relative;
      .audi-brand-login {
        align-items: center;
        display: flex;
        flex: 0 0 33.3333%;
        justify-content: right;
        width: 33.3333%;
      }
      .audi-image-wrapper {
        background: url("/img/car.png") no-repeat center center / cover;
        flex: 0 0 66.6666%;
        width: 66.6666%;
        img {display: none;}
      }
      #content {
        min-width: 340px;
      }
    }
  }

  .register-user-screen {
    .landing-wrapper {
      position: relative;
      .audi-brand-login {
        align-items: center;
        display: flex;
        flex: 0 0 33.3333%;
        justify-content: right;
        width: 33.3333%;
      }
    }
  }

}


@media all and (min-width: 1440px) {

  .login-screen {
    .landing-wrapper {
      #content {
        min-width: 380px;
        padding: 2rem 2.5rem;
      }
    } 
    .login-form-actions {
      margin: 1.5rem 0 0;
    }
  }

  .register-user-screen {
    .login-form-actions {
      margin: 1.5rem 0 0;
    }
  }

}