#banner {
	#banner-inner {
		max-width: $max-width;
		padding: 1.5em 0 0;
	}
}

.hero-block {
  background: none repeat scroll 0 0 #dadada;
  margin: 0 0 1.5em;
  padding: 0;
  position: relative;
  .callbacks_container {
    @include clearfix;
    ul#hero-slider {
      li {
        img {
          display: block;
        }
      }
    }
    a.prev {
      background: url("/img/hero-arrow-prev.png") no-repeat scroll 0 0 transparent;
      display: block;
      height: 40px;
      left: .5em;
      position: absolute;
      text-indent: -9999px;
      top: 45%;
      width: 40px;
      z-index: 800;
    }
    a.next {
      background: url("/img/hero-arrow-next.png") no-repeat scroll 0 0 transparent;
      display: block;
      height: 40px;
      text-indent: -9999px;
      position: absolute;
      right: .5em;
      top: 45%;
      width: 40px;
      z-index: 800;
    }
  }
}