.mobile-nav {
  @include border-radius(0);
  background: none no-repeat scroll 0 0 transparent;
  border-right: 1px solid #e5e8e7;
  color: #000;
  display: block;
  float: left;
  height: 48px;
  left: 0;
  margin: 0;
  padding: 0;
  position: relative;
  top: 0;
  width: 48px;
  #responsive-menu-button {
    display: block;
    height: 48px;
    text-indent: -9999px;
    width: 48px;
    .menu-icon {
      background: url("/img/global-icons.png") no-repeat scroll -87px 0 transparent;
      display: block;
      height: 18px;
      left: 14px;
      position: absolute;
      top: 16px;
      width: 17px;
    }    
  }
}


.sidr {
  background: none no-repeat scroll 0 0 $mobile-nav-slideout-bkgd;
  border: none;
  box-shadow: none;
  /*font-family: "Audi Type Ext";*/
  font-size: 1em;
  width: 240px;
  ul, .sidr-class-menu {
    border: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      border: none;
      display: block;
      height: 48px;
      line-height: 18px;
      position: relative;
      &:hover, &.active {
        box-shadow: none;
        a {
          box-shadow: none;
        }
      }
      &.sidr-class-active {
        a {
          border-left-color: $brand-red;
          box-shadow: none;
          &:hover {
            border-left-color: $brand-red;
          }
        }
      }
      a {
        border-left: 6px solid transparent;
        box-shadow: none;
        color: $mobile-nav-slideout-font-color;
        display: block;
        /*font-family: "Audi Type Ext";*/
        font-size: $mobile-nav-slideout-font-size;
        font-weight: normal;
        line-height: 48px;
        padding: 0;
        text-indent: 15px;
        &:hover, &.active {
          border-left-color: $white;
          box-shadow: none;
          text-decoration: underline;
        }
      }
      ul {display: none;}
    }
  }
}


#navigation {
  display: none;
  float: left;
  margin: 0 10px 0 0;
	padding: 0;
	position: relative;
	#navigation-inner {
		margin: 0 auto;
    max-width: $max-width;
		padding: 0;
    position: relative;
		.menu { /* Top Level */
      list-style: none;
      margin: 0;
      padding: 0;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      li {
        float: left;
        list-style: none;
        list-style-image: none;
        margin: 0;
        padding: 0;
        position: relative;
        &:first-child {border: none; padding: 0;}
        &.active {
          a {
            color: $main-nav-link-color-hover;
          }
          ul {
            li {
              a {
                border: none;
              }
            }
          }
        }
    	  a {
          color: $main-nav-link-color;
          display: block;
          font-size: 1rem;
          line-height: 1em;
          padding: 1rem;
          text-decoration: none;
          &:hover {
            color: $main-nav-link-color-hover;
            text-decoration: none;
          }
        }
    	  &:hover a, &:hover ul {
    	    left: -1px;
      	  top: 45px;
    	  }
    	  ul { /* Secondary */
          position: absolute;
          background: #fff;
          border: 1px solid $brand-pale-grey;
          width: 250px;
          padding: 0;
          left: -999em;
          z-index: 999;
          li {
            border: none;
            float: left;
            position: relative;
            margin: 0;
            padding: 0;
            width: 250px;
            text-align: left;
            &:last-child {border: none;}
            a {
        	    color: black;
        	    font-family: "Audi Type W01";
              font-size: .813rem;
        		  font-weight: normal;
        		  line-height: 1.25em;
        		  padding: .625em 1em;
        		  &:hover { 
                text-decoration: underline;
              }
      	    }
      	    ul { /* Tertiary */
              position: absolute;
              background: #000000;
              width: 250px;
              margin: 0 0 0 250px;
              padding: 0;
              top: 0;
              left: -999em;
              z-index: 999;
              li {
                border: none;
                float: left;
                margin: 0;
                padding: 0;
                width: 250px;
                a.sf-depth-3 {
                  display: block;
                  margin: 0;
                  padding: 1em;
                  &:hover { background: #228B22; color: #FFFFFF; }
                  &.sfHover a { background: #228B22; color: #FFFFFF; }
                }
              }
            }
          }
        }
      }
    }
	}
}
    
  


@media all and ( min-width: 1024px ) {
  
  .mobile-nav {
    display: none;
  }
  
  #navigation {
    background-color: $main-nav-region-bkgd;
    display: block;
    float: none;
    margin: 0;
    #navigation-inner {
      padding: 0 1rem 0 .375rem;
  		.menu { /* Top Level */
  		  li {
          a {
            font-size: .813rem;
          }
  		    ul {
            li {
              &:first-child {display: block;}
              &:nth-child(2) {border: 0; padding: 0;}
            }
          }
        }
      }
    }
  }
  
}



@media all and ( min-width: 1200px ) {
  
  .mobile-nav {
    display: none;
  }
  
  #navigation {
    #navigation-inner {
  		.menu { /* Top Level */
  		  li {
  		    a {
            font-size: 1rem;
          }
        }
      }
    }
  }
  
}
