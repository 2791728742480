#slidedeck_frame {
  .slide {
    h2 {
      margin: 0 0 .875em;
    }
    h3 {
      font-size: 1.125em;
    }
    p {
      line-height: 1.5em;
    }
    .image-block {
      margin: 0 0 1.5rem;
      img {
        height: auto !important;
        width: 100% !important;
      }
    }
    .text-block {
      position: relative;
      z-index: 100;
    }
  }
}
.skin-slidedeck {
  dl.slidedeck {
    margin: 0 0 2em;
    width: 100%;
    dd {
      width: 100% !important;
    }
  }
}

.slideshow-controls {
  position: absolute;
  top: 10%;
  width: 100%;
  .button {
    background-color: #000;
    background-image: url("/img/global-icons.png");
    background-repeat: no-repeat;
    min-width: 0;
    padding: 0;
    position: relative;
    z-index: 15;
    &.prev {
      background-position: -60px -208px;
      display: block;
      float: left;
      height: 30px;
      text-indent: -9999px;
      width: 30px;
      &:hover {
        background-position: -90px -208px;
      }
    }
    &.next {
      background-position: -30px -328px;
      display: block;
      float: right;
      height: 30px;
      text-indent: -9999px;
      width: 30px;
      &:hover {
        background-position: -60px -328px;
      }
    }
  }
}

.slideshow-pager {
  text-align: center;
  .button {
    background-color: transparent;
    background-image: url("/img/global-icons.png");
    background-position: -17px -101px;
    background-repeat: no-repeat;
    border: none;
    display: inline-block;
    height: 8px;
    margin: 0 3px 0 0;
    min-width: 0;
    padding: 0;
    text-indent: -9999px;
    vertical-align: middle;
    width: 8px;
    &.selected {
      background-position: -9px -101px;
    }
  }
}



/* Tablet styles 
  --------------------------------------------------------------------- */
@media all and (min-width: 480px) and (max-width: 959px) {}


/* Large Tablet 768+ styles 
  --------------------------------------------------------------------- */
@media all and (min-width: 768px) {
  
  #slidedeck_frame {
    .slide {
      h2 {
        margin: 0 0 .875em;
      }
      h3 {
        font-size: 1.125em;
      }
    }
  }
  
  .slideshow-controls {
    right: 0;
    top: 16%;
  }
  
  .slideshow-pager {
    bottom: 4em;
    padding: 0 0 0 34%;
    position: absolute;
  }
  
}


/* Desktop 960+ styles 
  --------------------------------------------------------------------- */
@media all and (min-width: 960px) {
  
  .skin-slidedeck {
    dl.slidedeck {
      margin: 0 0 2em;
    }
  }

  .slide-show-container {
    margin-left: 1.5rem;
  }
  
}


/* Desktop 1200 styles 
  --------------------------------------------------------------------- */
@media all and (min-width: 1200px) {
  
  .slideshow-controls {
    bottom: 4em;
  }
  
}


/* Desktop 1400 styles 
  --------------------------------------------------------------------- */
@media all and (min-width: 1400px) {
  
  .slideshow-controls {
    bottom: 4em;
  }
  
}