body {
  color: $base-font-color;
  font-family: "Audi Type W01";
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5em;
}

h1, h2, h3, h4, h5, h6 {
  color: $brand-black;
  font-family: "Audi Type W01 Ext Bold";
  font-weight: normal;
  line-height: initial;
  margin: .625rem 0;
  text-rendering: optimizeLegibility; // Fix the character spacing for headings
  a,a:link,a:visited { color: $brand-black; text-decoration: none; }
  a:hover { color: $brand-black; text-decoration: none; }
}

h1.page-title {
  font-size: 2em;
  margin: .5em 0 1em;
}

h1 {
  font-size: $base-font-size * 1.875;
}

h2 {
  font-size: $base-font-size * 1.75;
}

h3 {
  font-size: $base-font-size * 1.5; // 16 * 1.75 = 28px
}

h4 {
  font-size: $base-font-size * 1.375; // 16 * 1.5 = 24px
}

h5 {
  font-size: $base-font-size * 1.125; // 16 * 1.25 = 20px
}

h6 {
  font-size: $base-font-size;
}

p {
  margin: 0 0 $base-line-height;
}

a {
  color: $base-link-color;
  text-decoration: none;
  transition: color 0.2s ease-in 0s;
  &:hover {
    color: $hover-link-color;
    text-decoration: underline;
  }

  &:active, &:focus {
    color: $hover-link-color;
    outline: none;
  }
  &.caret {
    background: url("/img/cta-arrow.png") no-repeat scroll 3.5px 4.5px transparent;
    display: block;
    font-weight: bold;
    padding: 0 0 0 1.25em;
  }
}

ul.caret {
  list-style-image: url("/img/cta-arrow.png");
  margin: 1em 0;
  padding: 0 0 0 1.25em;
}


hr {
  border-bottom: 1px solid $medium-gray;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-line-height 0;
}

img {
  margin: 0;
  max-width: 100%;
}

abbr, acronym {
  border-bottom: 1px dotted $base-border-color;
  cursor: help;
}

address {
  display: block;
  margin: 0 0 ($base-line-height / 2);
}

hgroup {
  margin-bottom: $base-line-height / 2;
}

del {
  color: lighten($base-font-color, 15);
}

blockquote {
  border-left: 2px solid $base-border-color;
  color: lighten($base-font-color, 15);
  margin: $base-line-height 0;
  padding-left: $base-line-height / 2;
}

cite {
  color: lighten($base-font-color, 25);
  font-style: italic;

  &:before {
    content: '\2014 \00A0';
  }
}


@media all and (min-width: 768px) {

  h1 {
    font-size: $base-font-size * 2; // 16 * 2.25 = 36px
  }
  
  h2 {
    font-size: $base-font-size * 1.875; // 16 * 2 = 32px
  }
  
  h3 {
    font-size: $base-font-size * 1.5; // 16 * 1.75 = 28px
  }
  
  h4 {
    font-size: $base-font-size * 1.375; // 16 * 1.5 = 24px
  }
  
  h5 {
    font-size: $base-font-size * 1.25; // 16 * 1.25 = 20px
  }
  
  h6 {
    font-size: $base-font-size;
  }

}

@media all and (min-width: 1200px) {


  h1 {
    font-size: $base-font-size * 2.25; // 16 * 2.25 = 36px
  }
  
  h2 {
    font-size: $base-font-size * 1.875; // 16 * 2 = 32px
  }
  
  h3 {
    font-size: $base-font-size * 1.5; // 16 * 1.75 = 28px
  }
  
  h4 {
    font-size: $base-font-size * 1.375; // 16 * 1.5 = 24px
  }
  
  h5 {
    font-size: $base-font-size * 1.25; // 16 * 1.25 = 20px
  }
  
  h6 {
    font-size: $base-font-size * 1.125;
  }

}