.table-overflow, .table-wrapper {
	overflow-x: auto;
}

table {
	border-collapse: collapse;
	font-size: 1em;
	margin: ($base-line-height / 2) 0;
	table-layout: fixed;
	width: 100%;
	th, td {
		border-top: 1px solid $base-border-color;
		padding: 0.5em 1em;
	}
	th {
	  font-weight: bold;
	  text-align: left;
	  background-color: $brand-dark-grey;
	  color: #fff;
	}
	tr {
    &:nth-child(odd) {
      background: none no-repeat scroll 0 0 $light-gray;
    }
	}
	tr, td, th {
	  vertical-align: middle;
	}
	tr.even { background-color: $medium-gray; }
	tr.odd { background-color: $dark-gray; color: $light-gray; }
}

table.striping {
  thead {
    th {
      background: none no-repeat scroll 0 0 #000;
    }
  }
  tbody {
    tr {
      background: none no-repeat scroll 0 0 #fff;
      &:nth-child(odd) {
        background: none no-repeat scroll 0 0 $light-gray;
      }
    }
  }
}


@media all and (min-width: 768px) {

  
}