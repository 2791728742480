.error, #flash_failure {
  background: #fbe3e4;
  color: #96151b;
  font-weight: bold;
  margin-bottom: 0.75em;
  padding: 0.75em; }
  .error a, #flash_failure a {
    color: #6a0f13; }
    .error a:hover, #flash_failure a:hover {
      color: #110203; }

.notice, #flash_notice {
  background: #fff6bf;
  color: #8c7800;
  font-weight: bold;
  margin-bottom: 0.75em;
  padding: 0.75em; }
  .notice a, #flash_notice a {
    color: #594c00; }
    .notice a:hover, #flash_notice a:hover {
      color: black; }

.success, #flash_success {
  background: #e6efc2;
  color: #56651a;
  font-weight: bold;
  margin-bottom: 0.75em;
  padding: 0.75em; }
  .success a, #flash_success a {
    color: #333c10; }
    .success a:hover, #flash_success a:hover {
      color: black; }
	