#accordion {
    p {
        font-size: 12px;
        color: #666;
    }

    .ui-state-default {
        background: #efefef;
        border: 1px solid #4b90cd;
        color: #666;
    } 
    .ui-state-hover {
        color: #aaa;
        background: #efefef
    }
   
    

}